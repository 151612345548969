import { unified } from "unified";
import remarkParse from "remark-parse";
import rmarkDocx from "remark-docx";
import { saveAs } from "file-saver";
import rehypeSanitize from "rehype-sanitize";
import rehypeStringify from "rehype-stringify";
import remarkRehype from "remark-rehype";

export class DocxGenerator {
    static async convert(text: string) {
        const docx = await unified()
            .use(remarkParse)
            .use(rmarkDocx, {output: "blob"})
            .process(text);
        
        const blob: Blob = await docx.result as Blob;
        
        saveAs(blob, "Voorstel.docx");
    }
}

export class HtmlGenerator {
    static async convert(text: string): Promise<string> {
        const file = await unified()
            .use(remarkParse)
            .use(remarkRehype)
            .use(rehypeSanitize)
            .use(rehypeStringify)
            .process(text)

        return String(file);
    }
}