import {Prompt} from "../models/prompt/Prompt";
import {Configuration} from "../Configuration";
import {AuthContextProps} from "react-oidc-context";

export class PromptsRepository {
    baseApiUrl = Configuration.promptsApiBaseUrl;
    private auth: AuthContextProps;
    
    constructor(auth: AuthContextProps) {
        this.auth = auth;
    }
    
    async getActivePrompts (): Promise<Prompt[] | undefined> {
        const response = await fetch(`${this.baseApiUrl}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });
        
        if (response.status !== 200) {
            alert("Error retrieving prompts: " + response.status);
            return;
        }
        
        return await response.json() as Prompt[];
    }
    
    async getPrompts (category: string): Promise<Prompt[] | undefined>  {
        
        const response = await fetch(`${this.baseApiUrl}/${category}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });
        if (response.status !== 200) {
            alert("Error retrieving prompts: " + response.status);
            return;
        }
        
        return await response.json() as Prompt[];
    }
    
    async addPrompt (prompt: Prompt): Promise<Prompt | undefined> {
        prompt.promptId  = -1;
        const response = await fetch(`${this.baseApiUrl}/`, {
            // method: currentPrompt.promptId > 0 ? 'PUT' : 'POST',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
            body: JSON.stringify(prompt),
        });
        
        if (response.status !== 200) {
            alert("Error saving prompt: " + response.status);
            return;
        }
        
        return  await response.json() as Prompt;
    }
    
    async deletePrompt (prompt: Prompt): Promise<boolean> {
        const response = await fetch(`${this.baseApiUrl}/${prompt.promptId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });
        
        if (response.status !== 200 && response.status != 204) {
            alert("Error deleting prompt: " + response.status);
            return false;
        }
        
        return true;
    }
    
}