import React, {useEffect, useState} from "react";
import {useRequestState} from "../../hooks/RequestState";
import {
    Box,
    Button,
    Container,
    Paper,
    Typography
} from "@mui/material";
import {Download} from "@mui/icons-material";
import {HtmlGenerator, DocxGenerator} from "../../Utils/MarkdownConvert";

const Review = () => {
    const {requestState, updateRequestState} = useRequestState();
    const [text, setText] = useState("");

    useEffect(() => {
        parseMarkdown().catch();
    }, [requestState]);
    // return (
    //     <>Review
    //         {marked.parse(requestState.result ?? "", { breaks: true, gfm: true})}
    //     </>  
    // );
    
    const parseMarkdown = async () => {
        const markdown = requestState.result ?? "# No data";
        const html = await HtmlGenerator.convert(markdown);
        
        setText(html);
    }

    const handleDownload = async () => {
        await DocxGenerator.convert(requestState.result ?? "# No data");
    };

    return (
        <Container maxWidth="md" sx={{mt: 4, mb: 4}}>
            <Paper elevation={3} sx={{p: 3, bgcolor: "background.paper"}}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                    }}
                >
                    <img
                        src="/Logo.svg"
                        alt="Logo"
                        style={{height: "50px"}}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Download/>}
                        onClick={handleDownload}
                    >
                        Download als Word
                    </Button>
                </Box>

                <Typography variant="h6" gutterBottom>
                    Voorgesteld besluit
                </Typography>
                <Typography variant="body1" paragraph>
                </Typography>
                <div dangerouslySetInnerHTML={{__html: text}}/>
            </Paper>
        </Container>
    );
};


export default Review;