import React from "react";
import {ExcelIcon, PDFIcon, PowerPointIcon, WordIcon} from "./Icons";
import {IconButton, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useRequestState} from "../hooks/RequestState";

interface Props {
    files: File[];
    allowRemove: boolean
}

const FileList = ( {files, allowRemove, ...props}: Props ) => {
    const {requestState, updateRequestState} = useRequestState();
    
    const removeFile = (index: number) => {
        const newFiles = [...requestState.files];
        newFiles.splice(index, 1);
        // setFiles(newFiles);
        updateRequestState({
            ...requestState,
            files: newFiles
        });
    };
    
    const getFileIcon = (fileName: string) => {
        if (fileName.endsWith(".docx") || fileName.endsWith(".doc"))
            return <WordIcon/>;
        if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls"))
            return <ExcelIcon/>;
        if (fileName.endsWith(".pptx") || fileName.endsWith(".ppt"))
            return <PowerPointIcon/>;
        if (fileName.endsWith(".pdf"))
            return <PDFIcon/>;

        return <WordIcon/>; // Default to Word icon if unknown
    };
    
    if (files.length > 0) {
        return (
            <List>
                {files.map((file, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>{getFileIcon(file.name)}</ListItemIcon>
                        <ListItemText primary={file.name}/>
                        { allowRemove ?
                            <IconButton onClick={() => removeFile(index)}>
                                <Close/>
                            </IconButton>
                            : <></>
                        }
                    </ListItem>
                ))}
            </List>
        )
    }
    
    return (
        <></>
    )
};

export default FileList;
