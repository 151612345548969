import React, { useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {
    AppBar,
    Box, Button,
    Container,
    IconButton, Link,
    Menu, MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import RVAvatar from "./RVAvatar";
import {useNavigate} from "react-router-dom";
import {AuthHelpers, UserRole} from "../Utils/AuthHelpers";
import {MenuIcon} from "lucide-react";
import {Configuration} from "../Configuration";

function AdbIcon(props: { sx: { mr: number; display: { md: string; xs: string } } }) {
    return null;
}

const TopBar = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [pages, setPages] = useState<string[]>([]);

    useEffect(() => {
        let pages: string[] = [];
        if (AuthHelpers.isUserInrole(auth, UserRole.ClientAdmin)) {
            pages.push("Gebruikers")
        }
        if (AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
            pages.push("Prompts")
            pages.push("PromptFlows")
        }
        
        setPages(pages);
    }, []);
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = async ()  => {
        setAnchorElUser(null);
        // auth.removeUser(); 
        await auth.signoutRedirect()
        window.sessionStorage.clear()
    }

    function menuClick(evt:any) {
        console.log(evt.target)
        console.log(evt)
    }
    
    function handleClick(page: string) {
        switch (page.toLowerCase()) {
            case "prompts":
                navigate("/prompts");
                break;
            case "promptflows":
                navigate("/promptflows");
                break;
            default:
                break;
        }
        
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        RAKE VOORSTELLEN
                    </Typography>
                    {/*<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>*/}
                    {/*    <a href={"/"}>*/}
                    {/*        <img*/}
                    {/*            src="/Logo-Horizonral-White.svg"*/}
                    {/*            alt="Rake Voorstellen Logo"*/}
                    {/*            style={{width: 200}}*/}
                    {/*        />*/}
                    {/*    </a>*/}
                    {/*</Box>*/}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => {
                                    handleClick(page); 
                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => {
                                    handleClick(page); 
                                }}>
                                    <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        RAKE VOORSTELLEN
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Instellingen">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <RVAvatar />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem>
                                <Link href={Configuration.accountyUrl}>
                                    <Typography sx={{textAlign: 'center'}}>Account</Typography>
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <Typography sx={{textAlign: 'center'}}>Uitloggen</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        // <div>
        //     {auth.isAuthenticated ? <>{auth.user?.profile.name}</> : null}           
        // </div>
    )
};

export default TopBar;