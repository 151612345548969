import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
    show: boolean;
}

const LoadingIndicator = ({show} : IProps) => {
    return (
        <Backdrop
            sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
            open={show}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
}

export default LoadingIndicator;
