import React, {useEffect, useState} from "react";
import {Prompt} from "../../models/prompt/Prompt";
import {useAuth} from "react-oidc-context";
import {AuthHelpers, UserRole} from "../../Utils/AuthHelpers";
import {
    Box, Button,
    Container,
    Divider,
    List,
    ListItem,
    ListItemText, Paper,
    Typography
} from "@mui/material";
import { useNavigate} from "react-router-dom";
import {PromptsRepository} from "../../repositories/PromptsRepository";
import {Add} from "@mui/icons-material";
import LoadingIndicator from "../../components/LoadingIndicator";
import {DataGrid, GridRowParams} from "@mui/x-data-grid";

const PromptsList = () => {
    const auth = useAuth();
    const [ prompts, setPrompts ] = useState<Prompt[]>([]);
    const promptRepo = new PromptsRepository(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
            return
        }
        setIsLoading(true);
        getActivePrompts().catch(e => console.error(e));
    }, []);

    const getActivePrompts = async () => {
        let activePrompts = await promptRepo.getActivePrompts();
        if (activePrompts) {
            setPrompts(activePrompts);
        }
        setIsLoading(false);
    }

    const editPrompt = (category: string) => {
        navigate({
            pathname: `/prompts/edit/${category}`,
        });
    }
    
    const rowClick = (params: GridRowParams) => {
        console.log(params);
        navigate({
            pathname: `/prompts/edit/${params.id}`,
        });
    };

    const addPrompt = () => {
        navigate({
            pathname: `/prompts/new`,
        });
    };

    if (!AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
        return (
            <div>Unauthorized</div>
        );
    }
    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 3, bgcolor: "background.paper" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    {/*<History size={24} color={theme.palette.secondary.main} />*/}
                    <Typography variant="h5" sx={{ ml: 1 }} color="secondary">
                        Actieve prompts
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    sx={{ mb: 3 }}
                    onClick={addPrompt}>Nieuwe prompt</Button>
                <DataGrid
                    rows={prompts}
                    getRowId={(p: Prompt) => p.categoryId}
                    rowSelection={true}
                    onRowClick={rowClick}
                    columns={[
                        { field: "categoryId", headerName: "ID", minWidth: 150 },
                        { field: "categoryName", headerName: "Categorie", minWidth: 200 },
                        { field: "name", headerName: "Prompt naam", minWidth: 200 },
                        { field: "createdAt", headerName: "Datum", minWidth: 150 },
                    ]}
                />
                    
                {/*<List>*/}
                {/*    {prompts.map((prompt) => (*/}
                {/*        <React.Fragment key={prompt.promptId}>*/}
                {/*            <ListItem alignItems="flex-start"*/}
                {/*                      onClick={(e) => editPrompt(prompt.categoryId) }*/}
                {/*                      sx={{cursor: "pointer"}}>*/}
                {/*                <ListItemText*/}
                {/*                    primary={prompt.categoryName + ": " + prompt.name}*/}
                {/*                    secondary={*/}
                {/*                        <Typography*/}
                {/*                            sx={{ display: "inline" }}*/}
                {/*                            component="span"*/}
                {/*                            variant="body2"*/}
                {/*                            color="text.primary"*/}
                {/*                        >*/}
                {/*                            {prompt.createdAt.toString()}*/}
                {/*                        </Typography>*/}
                {/*                    }*/}
                {/*                />*/}
                {/*            </ListItem>*/}
                {/*            <Divider component="li" />*/}
                {/*        </React.Fragment>*/}
                {/*    ))}*/}
                {/*</List>*/}
                <LoadingIndicator show={isLoading} />
            </Paper>
        </Container>
    )
}
export default PromptsList
