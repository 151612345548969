import React from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

export const WordIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path
            d="M21.17 3.25q.33 0 .59.25q.24.24.24.58v15.84q0 .34-.24.58q-.26.25-.59.25H7.83q-.33 0-.59-.25q-.24-.24-.24-.58V17H2.83q-.33 0-.59-.24Q2 16.5 2 16.17V7.83q0-.33.24-.59Q2.5 7 2.83 7H7V4.08q0-.34.24-.58q.26-.25.59-.25m-.8 8.09l1.2 3.94H9.6l1.31-6.56H9.53l-.78 3.88l-1.11-3.75H6.5l-1.19 3.77l-.78-3.9H3.09l1.31 6.56h1.37m14.98 4.22V17H8.25v2.5m12.5-3.75v-3.12H12v3.12m8.75-4.37V8.25H12v3.13M20.75 7V4.5H8.25V7Z"/>
    </SvgIcon>
);

export const ExcelIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path
            d="M21.17 3.25q.33 0 .59.25q.24.24.24.58v15.84q0 .34-.24.58q-.26.25-.59.25H7.83q-.33 0-.59-.25q-.24-.24-.24-.58V17H2.83q-.33 0-.59-.24Q2 16.5 2 16.17V7.83q0-.33.24-.59Q2.5 7 2.83 7H7V4.08q0-.34.24-.58q.26-.25.59-.25M7 13.06l1.18 2.22h1.79L8 12.06l1.93-3.17H8.22L7.13 10.9l-.04.06l-.03.07q-.26-.53-.56-1.07q-.25-.53-.53-1.07H4.16l1.89 3.19L4 15.28h1.78m8.1 4.22V17H8.25v2.5m5.63-3.75v-3.12H12v3.12m1.88-4.37V8.25H12v3.13M13.88 7V4.5H8.25V7m12.5 12.5V17h-5.62v2.5m5.62-3.75v-3.12h-5.62v3.12m5.62-4.37V8.25h-5.62v3.13M20.75 7V4.5h-5.62V7Z"/>
    </SvgIcon>
);

export const PowerPointIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox={"0 15.016 512 482"}>
        <path
            d="M495.159 79.285s-128.126.378-128.908 2.982c-.42 1.262 91.3 53.473 91.3 53.473v194.32h37.608zM125.357 200.006c25.82-.682 32.636 3.667 34.922 21.75c.84 29.631-15.368 31-34.922 31zm0 80.434l17.6-.923c20.313-1.067 30.228-6.094 40.8-20.687c15.83-21.854 12.663-61.988-6.142-77.811c-10.096-8.496-18.83-9.913-51.736-8.395l-29.772 1.373s.627 160.17 1.393 160.937c.766.766 27.857 1.393 27.857 1.393zm315.48-140.505H290.41v33.428h114.91v66.857H290.41v29.25h114.91v20.893H290.41v31.339h114.91v20.893H290.41v31.339h114.91v20.893H290.41v31.339h150.428zM0 446.048V65.733l290.41-50.717V123.22h60.871s-1.304-44.223 0-47.966c4.166-11.948 8.495-12.623 81.034-12.623c68.513 0 73.066.574 77.238 9.73c3.263 7.16 3.263 257.522 0 264.682c-3.474 7.625-10.453 9.73-32.251 9.73h-19.894v60.579c0 32.156-2.394 35.476-29.398 35.528h-137.6v54.104z"/>
    </SvgIcon>
);

export const PDFIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox={"0 0 24 24"}>
        <path
            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9.5 11.5C9.5 12.3 8.8 13 8 13H7V15H5.5V9H8C8.8 9 9.5 9.7 9.5 10.5V11.5M14.5 13.5C14.5 14.3 13.8 15 13 15H10.5V9H13C13.8 9 14.5 9.7 14.5 10.5V13.5M18.5 10.5H17V11.5H18.5V13H17V15H15.5V9H18.5V10.5M12 10.5H13V13.5H12V10.5M7 10.5H8V11.5H7V10.5Z"/>
    </SvgIcon>
);
