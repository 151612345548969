import React from 'react';
import {createTheme, ThemeProvider} from "@mui/material";
import TopBar from "./TopBar";

const theme = createTheme({
    typography: {
        fontFamily: "Titillium Web, sans-serif",
    },
    palette: {
        // primary: {
        //     main: "#5743D9",
        // },
        primary: {
            main: "#5743D9",
        },
        secondary: {
            main: "#8C77D9",
        },
        background: {
            default: "#F2F2F2",
            paper: "#D5D0F2",
        },
        text: {
            primary: "#5545BF",
        },

    },
});

interface Props {
    children: React.ReactNode
}

const Layout = ( { children }: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <TopBar />
            {children}
        </ThemeProvider>
    )
}
export default Layout;