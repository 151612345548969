import {AuthContextProps} from "react-oidc-context";

export enum UserRole {
    User = "user",
    ClientAdmin = "client-admin",
    SuperAdmin = "super-admin",
}

export class AuthHelpers {
    public static isUserInrole(auth: AuthContextProps, role: UserRole): boolean {
        let roles: string[] = auth.user?.profile.roles as string[];
        
        return roles.indexOf(role) > -1;
    }
}