import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Add} from "@mui/icons-material";
import {PromptFlow} from "../../models/promptflow/PromptFlow";
import {useAuth} from "react-oidc-context";
import {PromptFlowRepository} from "../../repositories/PromptFlowRepository";
import {AuthHelpers, UserRole} from "../../Utils/AuthHelpers";
import LoadingIndicator from "../../components/LoadingIndicator";

const PromptFlowsList = () => {
    const auth = useAuth();
    const promptFlowRepo = new PromptFlowRepository(auth);
    const [ flows, setFlows ] = React.useState<PromptFlow[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    useEffect(() => {
        if (!AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
            return
        }
        setIsLoading(true);
        getFlows().catch(e => console.error(e));
    }, []);
    
    const getFlows = async () => {
        let flows = await promptFlowRepo.all();        
        if (flows !== undefined) {
            setFlows(flows);
        }
        setIsLoading(false);
    }
    
    const addFlow = () => {
        navigate('/promptflows/new')    
    }
    
    const editFlow = (id: number) => {
        navigate(`/promptflows/edit/${id}`)
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 3, bgcolor: "background.paper" }}>
               <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                   {/*<History size={24} color={theme.palette.secondary.main} />*/}
                   <Typography variant="h5" sx={{ ml: 1 }} color="secondary">
                       Prompt flows
                   </Typography>
               </Box>
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    sx={{ mb: 3 }}
                    onClick={addFlow}>Nieuwe flow</Button>
               <List>
                   { flows.map(flow => (
                       <React.Fragment key={flow.id}>
                           <ListItem key={flow.id} 
                                     onClick={() => editFlow(flow.id) }
                                     sx={{cursor: "pointer"}}>
                               <ListItemText primary={flow.id + ' ' + flow.name}
                                             secondary={flow.description} />
                           </ListItem>
                       </React.Fragment>
                   ))}
                    {/*{prompts.map((prompt) => (*/}
                    {/*    <React.Fragment key={prompt.promptId}>*/}
                    {/*        <ListItem alignItems="flex-start" */}
                    {/*                  onClick={(e) => editPrompt(prompt.categoryId) }*/}
                    {/*                  sx={{cursor: "pointer"}}>*/}
                    {/*            <ListItemText*/}
                    {/*                primary={prompt.categoryName}*/}
                    {/*                secondary={*/}
                    {/*                    <Typography*/}
                    {/*                        sx={{ display: "inline" }}*/}
                    {/*                        component="span"*/}
                    {/*                        variant="body2"*/}
                    {/*                        color="text.primary"*/}
                    {/*                    >*/}
                    {/*                        {prompt.createdAt.toString()}*/}
                    {/*                    </Typography>*/}
                    {/*                }*/}
                    {/*            />*/}
                    {/*        </ListItem>*/}
                    {/*        <Divider component="li" />*/}
                    {/*    </React.Fragment>*/}
                    {/*))}*/}
               </List>
                <LoadingIndicator show={isLoading}/>
           </Paper>
       </Container>
    );

}
export default PromptFlowsList;
