import React from 'react';
import {PromptFlowStep, Role} from "../../models/promptflow/PromptFlow";
import {
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField
} from "@mui/material";
import {Prompt} from "../../models/prompt/Prompt";
import {TrashIcon} from "lucide-react";

export interface FlowStepProps {
    item: PromptFlowStep;
    prompts: Prompt[];
    onUpdate: (item: PromptFlowStep) => void;
    onDelete: (item: PromptFlowStep) => void;
}
const FlowStep = ({ item, prompts, onUpdate, onDelete } : FlowStepProps) => {

    const handleRoleChange = (e: SelectChangeEvent, child?: any ) => {
        // updateItem({ ...currentItem, role: e.target.value as Role});
        const i = { ...item, role: e.target.value as Role};
        onUpdate(i);
    }
    
    const handlePromptSelectChange = (e: SelectChangeEvent, child?: any ) => {
        const i = { ...item, promptCategoryId: e.target.value};
        onUpdate(i);
    };

    const handlePromptTextChange = (e: any ) => {
        const i = { ...item, prompt: e.target.value};
        onUpdate(i);
    };
    
    const hasCategory = () : boolean => {
        return item.promptCategoryId != null && item.promptCategoryId !== "-1";
    }
    
    const isAssistant = () : boolean => {
        return item.role === Role.Assistant;
    }

    const deleteStep = async (item: PromptFlowStep) => {
        if (!window.confirm("Weet je zeker dat je deze stap wilt verwijderen?")) {
            return;
        }
        onDelete(item);
    };
    
    return (
            <Stack key={item.id} sx={{width: "100%"}} divider={<br />}>
                <Stack direction="row"
                       divider={<Divider orientation="vertical" flexItem />}
                       spacing={2}>
                    <FormControl sx={{flex: "1 1 auto", maxWidth: "45%"}}>
                        <InputLabel id="role-select-label">Chat rol:</InputLabel>
                        <Select
                            labelId="role-select-label"
                            value={`${item.role}`}
                            label="Chat Role"
                            onChange={handleRoleChange}>

                            <MenuItem value={Role.System}>System</MenuItem>
                            <MenuItem value={Role.Assistant}>Assistent</MenuItem>
                            <MenuItem value={Role.User}>User</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{flex: "1 1 auto", maxWidth: "45%"}}>
                        <InputLabel id="prompt-select-label">Prompt:</InputLabel>
                        <Select
                            labelId="prompt-select-label"
                            value={item.promptCategoryId ?? "-1"}
                            label="Prompt"
                            disabled={isAssistant()}
                            onChange={handlePromptSelectChange}>
                            {prompts.map((item) => (
                                <MenuItem key={item.promptId}
                                          value={item.categoryId}>{item.categoryName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <IconButton aria-label={"Delete"}
                                onClick={() => deleteStep(item).catch()}>
                        <TrashIcon/>
                    </IconButton>
                </Stack>
                
                <FormControl sx={hasCategory() || isAssistant() ? {display: "none"} : {}}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={item.prompt}
                        onChange={handlePromptTextChange}
                        placeholder="Voer hier de nieuwe prompt in..."
                        disabled={hasCategory()}
                        sx={{mb: 2}}
                    />
                </FormControl>
            </Stack>
    );
}

export default FlowStep;