import React, {ReactNode} from 'react';
import {AuthProvider, useAuth, hasAuthParams} from "react-oidc-context";

interface Props {
    children: React.ReactNode
}

const LoginHelper = ({ children } : Props ) => {
    const auth = useAuth();

    const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }
    
    // console.log(auth);

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <>{children}</>
            // <div>
            //     Hello {auth.user?.profile.name}{" "}
            //     <button onClick={() => void auth.removeUser()}>Log out</button>
            //     {children}
            // </div>
        );
    }

    return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}

export interface IAuthProvider {
    authConfig: any;
    children: ReactNode;
}

export function Authenticator({ authConfig, children }:IAuthProvider) : JSX.Element {
    return (
    <AuthProvider {...authConfig}>
        <LoginHelper>{children}</LoginHelper>
    </AuthProvider>
    );
}
