import {ObjectState} from "../Shared";

export class PromptFlow {
    id: number;
    name: string;
    description: string;
    flowType: FlowType;
    isActive: boolean = false;
    promptFlowSteps: PromptFlowStep[];
    
    constructor() {
        this.id = -1;
        this.name = "";
        this.description = "";
        this.flowType = FlowType.Proposal;
        this.isActive = true;
        this.promptFlowSteps = [];
    }
}

export enum FlowType {
    // Proposal = 1,
    // Feedback = 2,
    Proposal = "proposal",
    Feedback = "feedback",
}

export enum Role {
    System = "system",
    User = "user",
    Assistant = "assistant",
    // System = 1,
    // User = 2,
    // Assistant = 3,
}

export class PromptFlowStep {
    id: number;
    index: number;
    role: Role;
    promptCategoryId?: string;
    prompt?: string;
    state: ObjectState;

    constructor() {
        this.id = -1;
        this.role = Role.User;
        this.state = ObjectState.Unknown;
    }
}