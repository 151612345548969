import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

export type RequestState = {
    files: File[];
    jobId?: string;
    result?: string;
    generationType: GenerationType;
    promptFlowId: number;
}

export enum GenerationType {
    NotSet = -1, Improvement = 1, Proposal = 2, FlowStep = 3
}

type ContextType = {
    requestState: RequestState;
    updateRequestState: (state: RequestState) => void;
};

export const RequestStateContext = createContext<ContextType | undefined>(undefined);

export const RequestStateProvider = ({ children }: PropsWithChildren<{}>) => {
    const [ requestState,  setRequestState] = useState<RequestState>( 
        { files: [], generationType: GenerationType.NotSet, promptFlowId: -1 } );

    const updateState = (state: RequestState) => {
        setRequestState(state);
    }

    const value: any = {
        requestState: {
            ...requestState
        },
        updateRequestState: updateState
    };

    return (
        <RequestStateContext.Provider value={ value }>
            {children}
        </RequestStateContext.Provider>
    );
};

export const useRequestState = () => {
    const context = useContext(RequestStateContext);

    if (!context) {
        throw new Error('useRequestState no context, use in RequestStateProvider');
    }

    return context;
};
