export class RandomWords
{
    static #SIMPLE_WORDS = [
        "appel", "banaan", "fiets", "stoel", "computer", "tafel", "muur", "kat", "hond", "auto",
        "boom", "regen", "zon", "maan", "lucht", "kaars", "boek", "plant", "glas", "potlood",
        "papier", "telefoon", "raam", "deur", "water", "lamp", "hand", "stoel", "vork", "mes",
        "lepel", "bord", "tandenborstel", "zeep", "douche", "bad", "stoel", "bank", "kussen",
        "deken", "bed", "kleding", "jas", "broek", "shirt", "sokken", "schoenen", "pet", "hoed",
        "paraplu", "schaap", "koe", "varken", "kip", "paard", "vis", "vogel", "slang", "olifant",
        "leeuw", "tijger", "beer", "konijn", "hamster", "muis", "rat", "dolfijn", "walvis", "aap",
        "spin", "mug", "bij", "vlinder", "kever", "mier", "slak", "worm", "kikker", "hert", "vos",
        "uil", "pauw", "kikker", "arend", "honing", "ijs", "cake", "taart", "koekje", "brood",
        "boter", "kaas", "melk", "yoghurt", "thee", "koffie", "suiker", "zout", "peper", "olie",
        "azijn", "vlees", "kip", "vis", "fruit", "groente", "aardappel", "wortel", "broccoli",
        "sla", "tomaat", "komkommer", "paprika", "ui", "knoflook", "paddenstoel", "rijst", "pasta",
        "pizza", "soep", "saus", "sushi", "friet", "hamburger", "hotdog", "wafel", "pannenkoek",
        "poffertjes", "appelmoes", "stroop", "jam", "honing", "noten", "amandel", "pinda", "hazelnoot",
        "walnoot", "cashewnoot", "zonnebloem", "zaden", "meloen", "watermeloen", "aardbei", "framboos",
        "bosbes", "braam", "kiwi", "druif", "peer", "perzik", "nectarine", "kool", "spinazie", "boon",
        "linzen", "erwt", "mais", "roerei", "gebakken", "spiegelei", "omelet", "toast", "croissant",
        "pistolet", "baguette", "tosti", "sandwich", "burger", "wrap", "salade", "yoghurt", "smoothie",
        "sap", "cola", "limonade", "bier", "wijn", "whisky", "vodka", "rum", "cocktail", "champagne",
        "martini", "sangria", "gin", "tonic", "water", "bruiswater", "sinaasappelsap", "appelsap",
        "groentesap", "thee", "ijsthee", "cappuccino", "espresso", "latte", "mocha", "frisdrank",
        "energiesdrank", "koffiemelk", "chocolademelk", "kokosmelk", "amandelmelk", "sojamelk", "havermelk",
        "koekje", "chocolade", "snoep", "lolly", "kauwgom", "pepermunt", "chips", "popcorn", "notenmix",
        "mueslireep", "croissant", "quiche", "focaccia", "brioche", "pudding", "ijs", "sorbet", "yoghurtijs",
        "tiramisu", "cheesecake", "brownie", "macaron", "cupcake", "donut", "muffin", "boterham", "pasta",
        "lasagne", "spaghetti", "penne", "ravioli", "tagliatelle", "gnocchi", "cannelloni", "risotto",
        "tortellini", "fettuccine", "macaroni", "garnalen", "inktvis", "krab", "kreeft", "mosselen",
        "kokkels", "oesters", "scampi", "zeebaars", "tonijn", "zalm", "makreel", "haring", "kabeljauw",
        "heilbot", "forel", "zeeduivel", "baars", "mosselen", "sardines", "kipfilet", "kippenpoot",
        "kippenvleugel", "lamsvlees", "biefstuk", "gehakt", "spareribs", "stoofvlees", "ossenhaas",
        "varkenshaas", "rookworst", "kroket", "frikandel", "bamischijf", "loempia", "vlammetjes",
        "bitterballen", "kibbeling", "haring", "makreel", "stokbrood", "brie", "camembert", "goudse kaas",
        "parmezaan", "feta", "geitenkaas", "mozzarella", "cheddar", "blauwe kaas", "pecorino", "mascarpone",
        "ricotta", "roquefort", "gruyere", "emmentaler", "schimmelkaas", "yoghurt", "kwark", "roomkaas",
        "slagroom", "pindakaas", "hummus", "guacamole", "sambal", "mayonaise", "ketchup", "mosterd", "tahini",
        "pesto", "tapenade", "barbecuesaus", "chilisaus", "sojasaus", "hoisinsaus", "sesamolie", "olijfolie",
        "zonnebloemolie", "kokosolie", "boter", "ghee", "margarine", "bakpoeder", "bloem", "gist", "vanille",
        "kaneel", "kardemom", "nootmuskaat", "kruidnagel", "saffraan", "kurkuma", "komijn", "koriander",
        "dille", "basilicum", "oregano", "peterselie", "tijm", "rozemarijn", "laurier", "salie", "dragon",
        "bonenkruid", "munt", "gember", "citroen", "limoen", "sinaasappel", "pompoen", "bloemkool", "kool",
        "aubergine", "courgette", "peper", "radijs", "biet", "artisjok", "asperge", "broccoli", "boerenkool",
        "andijvie", "spinazie", "spruitjes", "raap", "knolselderij", "paksoi", "sperzieboon", "doperwt",
        "linzen", "bruine bonen", "witte bonen", "kikkererwten", "maïs", "haver", "tarwe", "rijst", "quinoa",
        "spelt", "gierst", "boekweit", "gerst", "kamut", "bulgur", "couscous", "polenta", "amandelmeel",
        "kokosmeel", "havermout", "bloem", "zilvervliesrijst", "basmatirijst", "jasmijnrijst", "wildrijst",
        "zwarte bonen", "kikkererwten", "tofu", "tempeh", "seitan", "falafel", "edamame", "wakame", "nori",
        "miso", "sojasaus", "tamari", "mirin", "sake", "sambal", "wasabi", "pickles", "kimchi", "sriracha",
        "truffel", "soep", "saus", "stoofpot", "curry", "chilisaus", "tahin", "appelmoes", "marmelade", "stroop",
        "pindakaas", "chocoladepasta", "gelei", "marmite", "vegemite", "honing", "ahornsiroop", "stroopwafel",
        "pannenkoek", "poffertjes", "wafel", "knäckebröd", "beschuit", "croissant", "cracker", "pretzel", "toast"
    ];

    static generateRandomName(numWords = 3, separator = "-") : string
    {
        let words = [];

        for (let i = 0; i < numWords; i++)
        {
            const index = (Math.random() * this.#SIMPLE_WORDS.length) | 0;
            const word = this.#SIMPLE_WORDS[index];
            words.push(word);
        }

        return words.join(separator);
    }
}
