import React, {useEffect, useState} from "react";
import {GenerationType, useRequestState} from "../../hooks/RequestState";
import {useAuth} from "react-oidc-context";
import {
    GetResultResponse,
    GetStatusResponse,
    ProcessingStatus,
    StartProcessingResponse
} from "../../models/proposal/Processing";
import {
    Box,
    Checkbox, CircularProgress,
    Container,
    CssBaseline,
    LinearProgress, List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useNavigate} from "react-router-dom";
import {Configuration} from "../../Configuration";

interface IProcessStepItem {
    name: string;
    label: string;
    progress: number;
    isComplete: boolean;
    key?: any;
}

const ProcessStep = ({ label, progress, isComplete } : IProcessStepItem) => (
    <ListItem key={label}>
        <ListItemIcon>
            <Checkbox
                edge="start"
                checked={isComplete}
                tabIndex={-1}
                disableRipple
                icon={<CheckCircleOutlineIcon />}
                checkedIcon={<CheckCircleOutlineIcon color="primary" />}
            />
        </ListItemIcon>
        <Box sx={{width:'100%'}}>
            <ListItemText primary={label}/>
            <Box sx={{display: 'block', alignItems: 'center', mt: 1}}>
                <Box sx={{width: '100%', mr: 1}}>
                    <LinearProgress variant={progress === 100 ? "determinate" : "indeterminate"} value={progress}/>
                </Box>
                {/*<Box sx={{minWidth: 35}}>*/}
                {/*    <Typography variant="body2"*/}
                {/*                color="text.secondary">{`${Math.round(progress)}%`}</Typography>*/}
                {/*</Box>*/}
            </Box>
        </Box>
    </ListItem>
);

const Processing = () => {
    const auth = useAuth();
    const {requestState, updateRequestState} = useRequestState();
    const [steps, setSteps] = useState<IProcessStepItem[]>([]);
    const navigate = useNavigate();
    const baseApiUrl = Configuration.proposalGeneratorApiBaseUrl;

    useEffect(() => {
        updateStage([]);
        startProcessing().catch(console.error);
    }, []);

    useEffect(() => {
        //Implementing the setInterval method
        if (requestState.jobId === undefined) {
            console.log("No job id")
            return 
        }
        const interval = window.setInterval(() => {
            updateStatus().then(completed => {
                if (completed) {
                    window.clearInterval(interval);
                    getResult().catch()
                }
            }).catch(console.error);

            console.log("tick", requestState.jobId);
        }, 1000);

        //Clearing the interval
        return () => window.clearInterval(interval);
    }, [requestState]);
    
    const startProcessing = async () => {
        const files = requestState.files;
        if(files.length === 0) {
            return
        }
        
        let formData = new FormData();
        for (let x = 0; x < files.length; x++) {
            formData.append('files', files[x]);
        }
        formData.append("generationType", `${requestState.generationType}`)
        formData.append("promptFlowId", `${requestState.promptFlowId}`)
        
        const response = await fetch(`${baseApiUrl}/start`, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
            body: formData,
        });
        
        let responseData = await response.json() as StartProcessingResponse;

        updateRequestState({ ...requestState, jobId: responseData.jobId });
    }
    
    const updateStatus = async (): Promise<boolean> => {
        const response = await fetch(`${baseApiUrl}/status/${requestState.jobId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
        let responseData = await response.json() as GetStatusResponse;
        console.log(responseData);
        return updateStage(responseData.status)
    }
    
    const getResult = async () => {
        const response = await fetch(`${baseApiUrl}/result/${requestState.jobId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
        let responseData = await response.json() as GetResultResponse;
        console.log(responseData);
        if (responseData?.result) {
            updateRequestState({
                ...requestState,
                result: responseData.result,
            })
            navigate('/preview');
        }
    }
    
    const updateStage = (statuses: ProcessingStatus[]): boolean => {
        const what = requestState.generationType === GenerationType.Improvement
            ? "Feedback" : "Voorstel";
        
        let steps = [
            {name:'upload', label: 'Document Upload', progress: 0, isComplete: true},
            {name:'convert', label: 'Tekstextractie', progress: 0, isComplete: false},
            {name:'process', label: 'Verwerken', progress: 0, isComplete: false},
            {name:'generate', label: `${what} genereren`, progress: 0, isComplete: false},
        ];

        for (let status of statuses) {
            let index = steps.findIndex(s => s.name === status.name);
            if (index > -1) {
                steps[index].progress = status.progress;
                steps[index].isComplete = status.progress >= 100;
            }
        }
        
        setSteps(steps);
        
        return steps[steps.length - 1].isComplete;
    }

    return (
        <>
            <CssBaseline/>
            <Container maxWidth="md" sx={{mt: 4}}>
                <Paper elevation={3} sx={{p: 3}}>
                    <Typography variant="h4" gutterBottom sx={{color: 'primary.main'}}>
                        { requestState.generationType === GenerationType.Improvement 
                            ? <>Feedback genereren</>
                            : <>Voorstel genereren</>
                        }
                    </Typography>
                    <List>
                        {steps.map((step, index) => (
                            <ProcessStep {...step} key={index} />
                        ))}
                    </List>
                </Paper>
            </Container>
        </>
    );

    // return (
    //     <>
    //         <h1>Processing....</h1>
    //         <FileList files={requestState.files} allowRemove={false} />
    //     </>
    // )
}

export default Processing;