import React from 'react';
import './App.css';

import {Route, Routes} from 'react-router-dom';
import AppRoutes from "./AppRoutes";
import {Authenticator} from "./components/Authenticator"
import Layout from "./components/Layout";
import {RequestStateProvider} from "./hooks/RequestState";
import {Configuration} from "./Configuration";
import {WebStorageStateStore} from "oidc-client-ts";

const oidcConfig = {
    authority: Configuration.authorityUrl,
    client_id: "rake-voorstellen",
    redirect_uri: window.location.origin + '/',// "http://localhost:53787/",
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const App = () => {
    console.log("REACT_APP_AUTHORITY_URL", process.env.REACT_APP_AUTHORITY_URL)
    console.log(Configuration.baseApiUrl, Configuration.authorityUrl);
    
    return (
        <Authenticator authConfig={oidcConfig}>
            <Layout>
                <RequestStateProvider>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const {element, ...rest} = route;
                            return <Route key={index} {...rest} element={element}/>;
                        })}
                    </Routes>
                </RequestStateProvider>
            </Layout>
        </Authenticator>
    )
}

export default App;
